.home-1{
    width: 100%;
    height: 100vh;
    background: burlywood;
    background-image: url("../../img/home/home-1.webp");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:cover;
    /* background-size: 100%; */
}
.h-1-bt-play{
    width: 15%;
    height: 5.5vh;
    color: whitesmoke;
    background: linear-gradient(to bottom, #ffac3f, #fe9800);
    border: 1px solid #e38901;
    box-shadow: 2px 2px 5px rgb(0,0,0,0.16);
    border-radius: 6px;
    position: absolute;
    left: 80%;
    top: 7.25%;
    font-family: "Roboto Slab", serif;
    font-size: 12px;
    font-weight: 900;
    text-shadow: 1px 1px rgb(0,0,0,0.4);
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;

}
.h-1-bt-play:hover{
    box-shadow: 0px 2px 8px #e38901;
}
.h-1-bt-discord{
    width: 4%;
    height: 8vh;
    /* background: #6f2bf7; */
    background-image: url("../../img/discord.png");
    background-position: 50% 50%;
    background-size: 100%;
    box-shadow: 1px 1px 6px rgb(0,0,0,0.16);
    border-radius: 8px;
    position:fixed;
    left: 93%;
    top: 88.5%;
    animation: shadow-bt-1 4s infinite;
    animation-delay: 3s;
    color: rgb(41, 41, 41);
    text-decoration:underline;
    transition: 0.3s;
    /* z-index: 9999; */
}
.h-1-bt-discord:hover{
    width: 4%;
    height: 8vh;
    animation: none;
}
/* .h-1-bt-whitepaper{
    position:absolute;
    left: 15%;
    top: 88%;
    text-decoration: underline;
} */
.h-1-icon{
    width: 16%;
    height: 16vh;
    /* background: red; */
    background-image: url("../../img/icon-full.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 90%;
    position: absolute;
    top: 3%;
    left: 5%;
    text-decoration: none;
    cursor:pointer;
}
.h-1-skade{
    width: 20%;
    height: 40vh;
    background-image: url("../../img/skade-badon-1.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 90%;
    position: absolute;
    animation: move1 4s linear infinite;
    top: 33%;
    left: 64.5%;
    z-index: 9999; 
}
.h-1-shadow{
    width: 18%;
    height: 8vh;
    background: rgb(0,0,0,0.24);
    position: absolute;
    animation: move-shadow-skade 4s linear infinite;
    top: 65.5%;
    left: 65%;
    border-radius: 100%;
    z-index: 9998;
    transform-origin: center;
}

@keyframes move1 {
    0% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(8px); 
    }
    100% {
        transform: translateY(-8px);
    }
}
@keyframes move-shadow-skade {
    0% {
        /* width: 12%; */
        transform: scaleX(90%);
        /* transform:translateX(50%,50%); */
        /* transform: translateY(-15px); */
    }
    50% {
        transform: scaleX(100%);
        /* width: 20%; */
        /* transform: translateY(15px);  */
    }
    100% {
        transform: scaleX(90%);
        /* width: 12%; */
        /* transform: translateY(-15px); */
    }
}

@keyframes shadow-bt-1 {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.nav-link{
    width: 34%;
    height: 6vh;
    /* background: red; */
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
   
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    text-shadow: 0px 0px 1px rgb(0,0,0), 1px 1px rgb(0,0,0,0.3) ;
}
.location-nav-link{
    top:7%;
    left: 29%; 
}
.h-1-nav-link{
    /* cursor: pointer; */
    height: 6vh;
    display: flex;
    align-items: center;
    /* background: blue; */
    transition: 0.3s;
    text-decoration: none;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    color: whitesmoke;
}
.h-1-nav-link:hover{
    color: #e2f255;
    
}
.h-1-bt-reveal-seacode{
    width: 42%;
    height: 90%;
    background: #223;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto Slab", serif;
    border: 1px solid rgb(0,0,255,0.1);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.3);
    color: white;
    text-shadow: 1px 1px black;
    margin-right: 4%;


}
.h-1-bt-reveal-seacode:hover{
    background: #334;
}
.h-1-bt-reveal-avx{
    width: 30%;
    height: 90%;
    background: white;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto Slab", serif;
    border: 1px solid rgb(0,0,255,0.3);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.3);
    margin-right: 4%;

}
.h-1-bt-reveal-avx:hover{
    background: #e1d5fb;
}
.h-1-bt-share{
    width: 30px;
    height: 30px;
    background-image: url("../../img/share-icon.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 40% 50%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 78%;
    left: 94%;
    cursor: pointer;
}

.h-1-group-1{
    width: 36%;
    height: 6vh;
    position: absolute;
    top: 86%;
    left: 6%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

}
.l-ref-1{
    font-size: 12px;
    color: #223;
    font-weight: 900;
    text-decoration: underline;
}