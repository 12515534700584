.public-sale-2{
    width: 100%;
    height: 100vh;
    background-image: url("../../img/public-sale/public-sale-3.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}
.p-b-link-2{
    position:absolute;
    top: 85%;
    left: 12.3%;
    color: aliceblue;
    font-family: monospace;   
}