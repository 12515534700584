
.scope-wallet-view{
    width: 100%;
    height: 90.92vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: linear-gradient(to bottom, rgb(25, 223, 213), rgb(90,34,255));
    
}
.swv-box-1{
    width: 60%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.swv-b1-title{
    width: 93%;
    height: 18%;
    padding-left: 7%;
    background-color: rgb(32, 32, 32);
    border-radius: 12px;
    font-size: 24px;
    display: flex;
    justify-content: initial;
    align-items: center;
    font-weight: 600;
    color: rgba(240, 240, 240, 0.986);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.swv-b1-bag{
    width: 98%;
    height: 76%;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap:wrap;
    overflow-x: auto;
}
.swv-b1-bag-box{
    width: 20%;
    height: 38%;
    padding: 1%;
    background: rgba(8, 8, 8, 0.24);
    margin: 1%;
    border-radius: 8px;
    border: 1px solid rgb(25, 24, 24,0.18);
    box-shadow: 1px 1px 6px rgb(25, 24, 24,0.18);
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: column;
    cursor:pointer;
}
.swv-b1-bag-box:hover{
    background: rgba(8, 8, 8, 0.34);
    cursor:pointer;
}
.swv-b1-bb-value{
    width: auto;
    height: auto;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 3%;
    padding-bottom: 3%;
    /* transform: translate(-100%, 0%); */
    top: 3%;
    left: -35%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: relative;
    background: rgba(43, 7, 206, 0.432);
    color: aliceblue;
    text-shadow: 1px 1px black;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.swv-b1-bb-img{
    margin-top: 5%;
    width: 70%;
    height: 50%;
    /* background: rgb(25, 24, 24,0.18); */
    background-image: url('../../img/demigod.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 90%;
    cursor:pointer;

}
.swv-b1-bb-img-2{
    margin-top: 5%;
    width: 70%;
    height: 50%;
    /* background: rgb(25, 24, 24,0.18); */
    background-image: url('../../img/chest.png');
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-size: 94%;
    cursor:pointer;

}
.swv-b1-bb-title{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: aliceblue;
    margin-top: 8%;
}
.swv-b1-bb-box-bt{}
.swv-b1-bb-bbt-1{}
.swv-b1-bb-bbt-2{}





.swv-b1-bag::-webkit-scrollbar {
    width: 8px;  
    height: 8px;
}
.swv-b1-bag::-webkit-scrollbar-track {
    background: rgba(80, 78, 78, 0.486);  /* Cor do trilho */
    border-radius: 10px;  /* Bordas arredondadas */
}
.swv-b1-bag::-webkit-scrollbar-thumb {
    background: rgb(32, 32, 32); /* Cor do polegar */
    border-radius: 11px; /* Bordas arredondadas */
    border: 1px solid rgb(59, 59, 59); 
}
.swv-b1-bag::-webkit-scrollbar-thumb:hover {
    background: #559; /* Cor do polegar ao passar o mouse */
    cursor:grab;
}
.swv-b1-bag::-webkit-scrollbar-corner {
    background: rgb(34, 33, 33);  /* Cor do canto */
}


.swv-box-2{
    background:  rgba(23, 37, 44, 0.589);
    width: 30%;
    height: 90%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: column;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(216, 216, 216);
}


.swv-b2-bar-1{
    /* margin-top: 5%; */
    width: 90%;
    height: 20%;
    /* background: rgb(25, 24, 24,0.18); */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}
.swv-b2-bar-2{
    width: 100%;
    font-weight: 600;
    height: 13%;
    background:  rgb(32, 32, 32);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 3.5%;
}
.swv-b2-b1-box-stitle-2{
    font-size: 21px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.swv-b2-b1-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 28%;
}

.swv-b2-b1-box-value-1{
    font-size: 30px;
    margin-bottom: 2%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.swv-b2-b1-box-stitle-1{
    font-size: 14px;
    color: rgba(240, 248, 255, 0.637);
}