*{margin:0; padding: 0; border: 0; cursor: url("../img/r-cursor.png") 4 4, pointer;}

.dcc{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dec{
    display: flex;
    justify-content:end;
    align-items: center;
}
.dic{
    display: flex;
    justify-content: initial;
    align-items: center;
}
.dac{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

::-webkit-scrollbar {
    width: 12px;  
    height: 12px;
    
    
  }

  ::-webkit-scrollbar-track {
    background: white;  /* Cor do trilho */
    border-radius: 10px;  /* Bordas arredondadas */
  }

  ::-webkit-scrollbar-thumb {
    background: #009dff; /* Cor do polegar */
    border-radius: 10px; /* Bordas arredondadas */
    border: 2px solid white; 
}

  ::-webkit-scrollbar-thumb:hover {
    background: #559; /* Cor do polegar ao passar o mouse */
    cursor:grab;
    }

  ::-webkit-scrollbar-corner {
    background: white;  /* Cor do canto */
  }


  
