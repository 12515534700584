.avx-4{
    width: 100%;
    height: 100vh;
    background-image: url("../../img/avx-4.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}
.a-4-box-whitepaper{
    width: 26%;
    height: 6vh;
    /* background: red; */
    position: absolute;
    top: 381vh;
    left: 64%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: roboto slab;
    color: #99a;
    font-size: 14px;
}
.a-4-link-whitepaper{
    color: #bbc;
    margin-left: 2%;
    cursor: pointer;
}