.home-3{
    width: 100%;
    height: 100vh;
    background: black;;
    background-image: url("../../img/home/home-2.webp");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:100% 100%;
    /* flex-direction: column; */
    /* border-bottom: 30px solid #f0f8ff;  */
}

