
.public-sale-view{
    width: 100%;
    height: 90.92vh;
    background: linear-gradient(to bottom, rgb(25, 223, 213), rgb(90,34,255));
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: column;
}
.psv-box-price{
    width: 95%;
    height: 15%;
    padding: 2.5%;
    /* background: rgb(69, 219, 69); */
    background: linear-gradient(to top, rgb(24, 26, 33), rgb(44, 46, 53));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.psv-bp-title-1{
    width: auto;
    height: auto;
    /* background: red; */
    color: rgb(233,233,244,0.91);
    /* text-shadow: 1px 1px black; */
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 72px;
    font-weight: 700;
    display: flex;
    align-items: end;
    justify-content: end;
}
.psv-bp-sub-title-1{
    width: auto;
    height: auto;
    /* background: red; */
    color: rgb(233,233,244,0.68);
    /* text-shadow: 1px 1px black; */
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: end;
    justify-content: end;
}
.psv-bp-title-2{
    width: auto;
    height: auto;
    margin-left: 1%;
    /* background: blue; */
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    display: flex;
    align-items: end;
    justify-content: initial;
}
.psv-box-buy-ps{
    width: 100%;
    height: 65%;
    padding-top: 1%;
    padding-bottom: 1%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.psv-box-growth-33{
    width: 95%;
    height: 10%;
    padding-left: 5%;
    background: rgb(0,0,0,0.3);
    color: rgba(208, 202, 239, 0.665);
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    display: flex;
    align-items: center;
    justify-content: initial;
}

.psv-bb-avxc{
    width: 42%;
    height: 76%;
    /* background: rgb(233,233,233,0.08); */
    background: linear-gradient(to top, #054c68, #ab2edd);
    /* border: 1px solid rgb(33,33,33, 0.66); */
    box-shadow: 1px 1px 12px rgb(0,0,0,0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: initial;
}
.psv-bba-box-1{
    width: 55%;
    height: 100%;
    padding-left: 5%;
    background: linear-gradient(to bottom, rgb(55, 0, 0,0.20), rgb(55, 0, 0,0.20));
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 60px;
    display: flex;
    justify-content: initial;
    align-items: initial;
    flex-direction: column;
}
.psv-bba-box-2{
    width: 45%;
    height: 100%;
    /* padding-left: 5%; */
    /* background: rgb(255, 0, 255,0.3); */
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border-bottom-right-radius: 60px; */
    /* border:1px solid rgb(255, 0, 0,0.3); */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;


}
.psv-bba-b1-icon{
    width: 58%;
    height: 40%;
    /* background: rgb(255, 0, 0,0.3); */
    background-image: url("../../img/website-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 70%;
    animation: rotate3 5s linear infinite;
}
@keyframes rotate3 {
    0% {
        transform-origin: center;
        transform: rotate(-8deg);
    }
    50% {
        transform-origin: center;
        transform: rotate(6deg);
    }
    100% {
        transform-origin: center;
        transform: rotate(-8deg);
    }
    
}

.psv-bba-b1-input{
    width: 60%;
    height: 13%;
    padding-left: 7%;
    margin-top: 18%; 
    background: rgb(0, 0, 0,0.24);
    border-radius: 4px;
    border: 1px solid rgb(0,0,0,0.14);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}
.psv-bba-b1-input::placeholder{
    color: rgba(255, 255, 255, 0.648);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 14px;
}
.psv-bba-b1-describ{
    width: 80%;
    height: 12%;
    margin-top: 1%;
    /* background: rgb(0, 0, 0,0.24); */
    color: rgb(255, 255, 255);
    /* text-shadow: 1px 1px #334; */
    font-size: 24px;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: row;
}
.psv-bba-b1-status{
    width: 90%;
    height: 12%;
    margin-top: 5%;
    border-radius: 4px;
    color: rgb(233,233,244,0.65);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.psv-bba-b1s-info{
    border: 1px solid rgba(255, 255, 255, 0.651);
    color: rgba(255, 255, 253, 0.596);
    width: 14px;
    height: 14px;
    border-radius:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.psv-bba-b1-status-2{
    width: 80%;
    height: 7%;
    margin-top: 1%;
    margin-left: 2%;
    /* background: rgb(255, 0, 0,0.3); */
    border-radius: 4px;
    color: rgba(255, 255, 253, 0.596);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: row;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.psv-bba-b1-bt{
    width: 50%;
    height: 12%;
    margin-top: 8%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.882);
    color: rgb(33,33,44,0.91);
    border: 1px solid rgb(0, 0, 0,0.24);
    box-shadow: 1px 1px 12px rgb(0, 0, 0,0.14);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    cursor:pointer;
}



















.psv-bb-demigod{
    width: 25%;
    height: 90%;
    background: #434950;
    /* border: 1px solid rgb(233,233,233, 0.04); */
    box-shadow: 1px 1px 12px rgb(0,0,0,0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: column;
}
.psv-bb-d-status{
    width: 80%;
    height: 12%;
    margin-top: 4%;
    border-radius: 4px;
    color: rgb(202, 248, 193);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}
.psv-bb-d-icon{
    width: 45%;
    height: 33%;
    /* background: rgb(0, 0, 0,0.24); */
    background-image: url('../../img/demigod.svg');
    background-position: 50% 50%;
    background-size: 80%;
    background-repeat: no-repeat;
}
.psv-bb-d-title{
    margin-top: 5%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 28px;
    color: white;
}
.psv-bb-d-box1{
    width: 80%;
    height: 14%;
    margin-top: 5%;
    /* background: rgb(255,0,0,0.3); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.psv-bb-d-input{
    width: 52%;
    height: 76%;
    padding-left: 6%;
    /* margin-top: 5% ;  */
    background: rgb(0, 0, 0,0.24);
    border-radius: 4px;
    border: 1px solid rgb(0,0,0,0.14);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    cursor: pointer;
    
}
.psv-bb-d-input::placeholder{
    color: rgba(255, 255, 255, 0.448);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 13px;
}
.psv-bb-d-bt{
    cursor: pointer;
    width: 25%;
    height: 76%;
    margin-left: 3%;
    /* margin-top: 7%; */
    background-color: rgb(0, 51, 255);
    font-weight: 600;
    color: rgb(255, 255, 255,0.91);
    display: flex;
    font-size: 13px;
    border: 1px solid rgb(0, 0, 0,0.24);
    justify-content: center;
    align-items: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 8px;
    box-shadow: 1px 1px 6px rgb(0, 0, 0,0.24);

}
.psv-bb-d-describ{
    margin-top: 5%;
    font-family:Verdana, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color:rgb(255, 205, 215);
}



.psv-bb-ark{
    width: 25%;
    height: 90%;
    background: #434950;
    /* border: 1px solid rgb(233,233,233, 0.04); */
    box-shadow: 1px 1px 12px rgb(0,0,0,0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: column;
}

.psv-bb-ark-icon{
    width: 45%;
    height: 33%;
    /* background: rgb(0, 0, 0,0.24); */
    background-image: url('../../img/chest.png');
    background-position: 50% 95%;
    background-size: 80%;
    background-repeat: no-repeat;
}

