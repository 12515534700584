.panel-1{
    width: 100%;
    height: 100vh;
    background: white;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}


/*  */
.panel-nav-left{
    height: 100vh;
    width: 20%;
    position: absolute;
    top: 0vh;
    left: 0%;
    background: rgb(29,39,36);
    border-right: 1px solid rgb(215, 244, 217, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: initial;
}
.pnl-logo{
    width: 100%;
    height: 15vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    /* background: rgba(245, 245, 245, 0.31); */
    background-image: url("../../img/icon-full.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 60%;
}
.pnl-nav-1{
    width: 85%;
    height: 60vh;
    background: rgb(215, 244, 217, 0.04);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    border: 1x solid rgb(29,39,36, 0.04);
}
.pnl-n1-link{
    width: 85%;
    height: 7.5vh;
    margin-top: 5%;
    background: rgb(215, 244, 217, 0.16);
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.pnl-n1-link-select{
    /* background: rgb(5, 14, 31); */
    background: #0df17f;
    color: rgb(0,0,0,0.9);
    font-weight: 400;

}
.pnl-n1-link-noSelect{
    background: rgb(215, 244, 217, 0.04);
}
.pnl-n1-link:hover{
    background: rgb(215, 244, 255, 0.4);
    
}
.block-link{
    color: gray;
    cursor: no-drop;
}
.link-end{
    margin-bottom: 5%;
}

.pnl-seacode-box{
    width: 85%;
    height: 15.5vh;
    background: rgb(215, 244, 217, 0.04);
    border-radius: 12px;
    margin-top: 2.5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border: 1x solid rgb(29,39,36, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;


}

.pnl-s-growth-1{
color: rgba(240, 248, 255, 0.626);
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: 11px;
}
.pnl-s-growth-2{
color: rgba(240, 248, 255, 0.848);
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.pnl-s-value{
/* color: orange; */
font-family: 'Courier New', Courier, monospace;
width: 63%;
padding-right: 2%;
display: flex;
align-items: center;
justify-content: end;
/* background: red; */
animation: color3 3s linear infinite;
}
@keyframes color3 {
    0% {
        color: #0df17f;
    }
    50% {
        color: aqua; 
    }
    75% {
        color: #cc29db;
    }
    100% {
        color: #0df17f;
    }
}
.pnl-s-box-1{
    width: 90%;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.pnl-s-bt-copy{
    width: 18%;
    height: 100%;
    padding-left: 2%;
    /* background: blue; */
    background-image: url('../../img/copy.png');
    background-position: 10% 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    cursor: pointer;

}

/*  */
.panel-nav-top{
    height: 9vh;
    width: 80%;
    position: absolute;
    top: 0vh;
    background: #e9f3e9;   
    left: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
}
.pnt-box-premium{
    display: flex;
    /* background: red; */
    margin-left: 2%;
    align-items: center;
    justify-content: initial;
    flex-direction: row;
    width: 18%;
    height: 8vh;
}
.pnt-status-premium-1{
    width: 30%;
    height:7vh;
    /* background: blue; */
    background-image: url("../../img/premium.png");
    background-position: 50% 0%;
    background-size: 60%;
    background-repeat: no-repeat;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    font-weight: 500;
    font-size: 12px;
    color: rgb(29,39,36,0.7);
}
.pnt-bt-premium{
    width: 28%;
    height: 4vh;
    margin-left: 6%;
    background: linear-gradient(to bottom, rgba(158, 45, 233, 0.825), rgba(24, 106, 138, 0.825));
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 25px;
    /* border: 1px solid rgb(0,0,0,0.16); */
    color: rgba(222, 221, 221, 1);
    cursor: pointer;
    box-shadow: 0px 0px 3px rgb(49, 59, 59,0.4);
}
.pnt-bt-premium:hover{
    background: linear-gradient(to top, rgba(158, 45, 233, 0.825), rgba(24, 106, 138, 0.825));

}
.pnt-price-premium{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    color: rgba(29,39,36,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    


}
.pnt-box-AVXC{
    background: #aeefef;
    border: 1px solid #28323229;
    height: 5.2vh;
    width: 13%;
    margin-left: 45%;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pnt-value{
    height: 100%;
    width: 60%;
    color: rgb(40,50,50,0.76) ;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 500;
    font-size: 13px;
    /* text-shadow: 1px 1px rgb(87, 86, 86); */
    display: flex;
    align-items: center;
    justify-content: initial;
}
.pnt-AVXC-logo{
    width: 30%;
    height: 100%;
    background-image: url("../../img/icon-avx.png");
    background-position: 50% 50%;
    background-size: 55%;
    background-repeat: no-repeat;
}
.pnt-AVXC-name{
    font-family: 'roboto slab';
    font-weight: 600;
    font-size: 12px;
    color: aqua;
}


.pnt-box-GLP{
    background: #aeefef;
    border: 1px solid rgb(40,50,50,0.16);
    height: 5.2vh;
    width: 13%;
    margin-left: 3%;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pnt-GLP-logo{
    width: 30%;
    height: 100%;
    background-image: url("../../img/glp.png");
    background-position: 50% 50%;
    background-size: 44%;
    background-repeat: no-repeat;
}


/*  */
.panel-viewport{
    height: 91vh - 1px;
    width: 80%;
    position: absolute;
    background: white;
    top: 9vh;
    left: 20%;
    border-top: 1px solid rgb(40,50,50,0.25);
}


/*  */
.pnl-nav-1::-webkit-scrollbar {
    width: 8px;  
    height: 8px;
}

.pnl-nav-1::-webkit-scrollbar-track {
    background: rgba(230, 230, 230, 0.114);  /* Cor do trilho */
    border-radius: 10px;  /* Bordas arredondadas */
}

.pnl-nav-1::-webkit-scrollbar-thumb {
    background: #62b88d; /* Cor do polegar */
    border-radius: 11px; /* Bordas arredondadas */
    border: 1px solid rgb(25, 24, 24); 
}

.pnl-nav-1::-webkit-scrollbar-thumb:hover {
    background: #559; /* Cor do polegar ao passar o mouse */
    cursor:grab;
}

.pnl-nav-1::-webkit-scrollbar-corner {
    background: white;  /* Cor do canto */
  }