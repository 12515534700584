
.dashboard-view{
    width: 100%;
    height: 90.92vh;
    background: linear-gradient(to bottom, rgb(25, 223, 213), rgb(90,34,255));
    /* border-top: 1px solid rgb(40,50,50,0.4); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dv-title{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 72px;
    font-weight: 600;
    color: white;
}
.dv-title-2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.74);
}
.dv-title-3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.54);
    margin-top: 2%;
}
.dv-box-1{
    width: 60%;
    height: 8%;
    margin-top: 2%;
    /* background-color: brown; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
.dv-box-1-bt-1{
    width: 18%;
    height: 70%;
    margin-left: 3%;
    margin-right: 3%;
    background: rgb(46, 46, 75);
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    border-radius: 8px;
}

.dv-box-2{
    width: 40%;
    height: 43%;
    margin-top: 2%;
    background: linear-gradient(to top, rgb(46, 46, 75), rgb(38, 38, 75));
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    border-radius: 8px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    box-shadow: 2px 2px 7px rgb(46, 46, 46,0.12);
}
.dv-box2-title{
    width: 83%;
    margin-bottom: 3%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 25px;
    color: #d3b3f8;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dv-box2-box{
    width: 85%;
    height: 55%;
    padding-left: 3%;
    /* background: rgba(54, 54, 54, 0.212); */
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    flex-wrap:  wrap;
    overflow-x: auto;

}
.dv_box2_box-title{
    /* margin-top: 3%; */
    font-size: 17px;
    font-weight: 600;
    color: rgb(227, 227, 235);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.dv_box2_box-news{
    width: 88%;
    margin-bottom: 6%;
    font-size: 13px;
    color:rgb(161, 164, 167);
}



/*  */
.dv-box2-box::-webkit-scrollbar {
    width: 8px;  
    height: 8px;
}

.dv-box2-box::-webkit-scrollbar-track {
    background: rgba(230, 230, 230, 0.114);  /* Cor do trilho */
    border-radius: 10px;  /* Bordas arredondadas */
}

.dv-box2-box::-webkit-scrollbar-thumb {
    background: #e9e4f5; /* Cor do polegar */
    border-radius: 11px; /* Bordas arredondadas */
    border: 1px solid rgb(247, 222, 255); 
}

.dv-box2-box::-webkit-scrollbar-thumb:hover {
    background: #559; /* Cor do polegar ao passar o mouse */
    cursor:grab;
}

.dv-box2-box::-webkit-scrollbar-corner {
    background: white;  /* Cor do canto */
  }