.home-9{
    width: 100%;
    height: 100vh;
    /* background: #545454; */
    /* background: burlywood; */
    background-image: url("../../img/home/home-6.webp");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    flex-direction: column;
    /* border-bottom: 30px solid aliceblue;  */
}
.h-9-bt-1{
    width: 20%;
    height: 7vh;
    color: black;
    background: #ffffff;
    border: 1px solid rgb(0,0,0,0.12);
    box-shadow: 2px 2px 3px rgb(0,0,0,0.3);
    /* border-radius: 30px; */
    border-top-left-radius: 6px;
    border-bottom-left-radius: 90px;
    border-top-right-radius: 90px;
    border-bottom-right-radius: 6px;

    position: absolute;
    left: 6.5%;
    top: 543vh;
    font-family: "Roboto Slab", serif;
    font-size: 13px;
    font-weight: 900;
    /* text-shadow: 1px 1px rgb(0,0,0,0.3); */
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}
.h-9-bt-1:hover{
    box-shadow: 0px 2px 8px rgba(29, 229, 243, 0.233);
}

.h-9-icon{
    width: 15%;
    height: 15vh;
    color: white;
    /* background: red; */
    background-image: url("../../img/icon-full.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    flex-direction: column;
    position:absolute;
    top: 584.5vh;
    left: 0.5%;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* opacity: 0; */
}
.h-9-bt-connect{
    width: 16%;
    height: 6vh;
    color: white;
    background: linear-gradient(to bottom, #ffac3f, #fe9800);
    border: 1px solid #e38901;
    box-shadow: 2px 2px 5px rgb(0,0,0,0.16);
    border-radius: 6px;
    /* border-bottom-right-radius: 30px;
    border-top-right-radius: 30px; */
    position: absolute;
    left: 80%;
    top: 589vh;
    font-family: "Roboto Slab", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 900;
    text-shadow: 2px 1px rgb(0,0,0,0.4);
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    /* opacity: 0; */

}
.h-9-bt-connect:hover{
    box-shadow: 0px 2px 8px #e38901;
}
.h-9-end-navbar{
    width: 100%;
    height: 16vh;
    background: linear-gradient(to bottom, #46577a, #1E2534);
    top: 584vh;
    left: 0%;
    /* border-top: 1px solid rgb(89, 89, 107); */
    box-shadow: 12px -12px 30px rgba(19, 0, 45, 0.7);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-family: "Roboto Slab", serif;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: gray;
    /* animation: move 3s linear infinite; */
    /* opacity: 0; */
}
@keyframes move999 {
    0% {
        background: linear-gradient(to left, rgb(0, 225, 255), #1E2534, rgb(183, 0, 255));
        /* transition: 0.3s; */
    }
    50% {
        background: linear-gradient(to left, #1E2534, rgb(0, 238, 255), #1E2534);
        /* transition: 0.3s; */
    }
    100% {
        background: linear-gradient(to right, rgb(0, 225, 255), #1E2534, rgb(183, 0, 255));
        /* transition: 0.3s; */
    }
}
.h-9-nav-link{
    /* cursor: pointer; */
    height: 6vh;
    display: flex;
    align-items: center;
    /* background: blue; */
    transition: 0.3s;
    text-decoration: none;
    margin-left: 1%;
    margin-right: 1%;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    color: #fefeff;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.233);
}
.h-9-nav-link:hover{
    color: #e2f255;
    
}