
.seacode-panel-view{
    width: 92%;
    height: 82.92vh;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 4vh;
    padding-bottom: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background: linear-gradient(to bottom, rgb(25, 223, 213), rgb(90,34,255));
}
.spv-container-1{
    width: 100%;
    height: 28%;
    background: rgb(63, 243, 204);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.spv-c-1-title{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 60px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #334;
}
.spv-c-1-sub-title{
    margin-top: 1%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 19px;
    color: rgb(0,0,0,0.6);
}

.spv-container-2{
    width: 100%;
    height: 68%;
    background: rgb(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.spv-c-2-box-1{
    background: rgb(255,255,255,0.1);
    width: 35%;
    padding-left: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    color: aliceblue;
}
.spv-c2-b1-title-1{
    color: rgb(255,255,255,0.66);
}
.spv-c2-b1-value{
    font-size: 24px;
}
.spv-c2-b1-title-2{

    margin-top: 10%;
    color: rgb(255,255,255,0.66);
}
.spv-c2-b1-title-3{

    margin-top: 2%;
    color: rgb(255,255,255,0.46);
    font-size: 13px;
    margin-left: 1%;
}
.spv-c2-b1-input{
    width: 45%;
    margin-top: 3%;
    padding-left: 5%;
    height: 8%;
    border-radius: 4px;
    background: rgb(235,235,255,0.9);
    /* border: 1px solid rgb(0,0,0,0.24); */
    box-shadow: 1px 1px 4px rgb(0,0,0,0.54);

}
.spv-c2-b1-bt{

    width: 32%;
    height: 9%;
    margin-top:7%;
    background: linear-gradient(to top, rgb(63, 243, 204), rgb(4, 209, 148));
    border: 1px solid rgb(0,0,0,0.3);
    color: rgba(8, 8, 8, 0.75) ;
    font-size: 14px;
    /* text-shadow: 1px 2px black; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 12px rgb(0,0,0,0.24);
    cursor: pointer;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 4px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;

}
.spv-c2-b1-bt:hover{
    background: linear-gradient(to top, rgb(53, 202, 170), rgb(7, 155, 110));

}








.spv-c-2-box-2{
    background: rgb(255,255,255,0.15);
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;

}
.spv-c-2-b-2-value{
    font-size: 72px;
}
.spv-c-2-b-2-describ{
    font-size: 24px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: rgb(255,255,255,0.7);
}
.spv-c-2-b-2-bt{
    width: 36%;
    height: 9%;
    margin-top:15%;
    background: linear-gradient(to bottom, rgb(255, 187, 0), rgb(189, 209, 4));
    border: 1px solid rgb(0,0,0,0.3);
    color: rgb(0,0,0,0.75) ;
    font-size: 14px;
    /* text-shadow: 1px 2px black; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 1px 1px 12px rgb(0,0,0,0.24);
    cursor: pointer;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.spv-c-2-b-2-bt:hover{
    background: linear-gradient(to bottom, rgb(206, 152, 2), rgb(189, 209, 4));
}
.spv-c-2-b-2-text{
    width: 85%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgba(255, 255, 255, 0.622);
    font-size: 15px;
}











.spv-c-2-box-3{
    background: rgb(255,255,255,0.20);
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.spv-c2-b3-title{

    font-size: 32px;
    color: aliceblue;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.spv-c2-b3-sub-title-1{
    color: rgb(255, 255, 255,0.67);
    margin-top: 6%;
    width: 50%;
    font-size: 15px;
}
.spv-c2-b3-sub-title-2{
    color: rgb(255, 255, 255,0.67);
    margin-top: 3%;
    width: 50%;
    font-size: 15px;
}
.spv-c2-b3-sub-title-3{
    color: rgb(255, 255, 255,0.67);
    margin-top: 3%;
    width: 50%;
    font-size: 15px;
}
.spv-c2-b3-input{
    width: 45%;
    margin-top: 2%;
    padding-left: 5%;
    height: 8%;
    border-radius: 4px;
    background: rgb(235,235,255,0.9);
    /* border: 1px solid rgb(0,0,0,0.24); */
    box-shadow: 1px 1px 4px rgb(0,0,0,0.54);
}
.spv-c2-b3-bt{
    width: 36%;
    height: 9%;
    margin-top:9%;
    background: linear-gradient(to top, rgb(63, 243, 204), rgb(4, 209, 148));
    border: 1px solid rgb(0,0,0,0.3);
    color: rgba(0, 0, 0, 0.75) ;
    font-size: 14px;
    /* text-shadow: 1px 2px black; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 1px 1px 12px rgb(0,0,0,0.24);
    cursor: pointer;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.spv-c2-b3r-back{
    cursor: pointer;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.767);
}
.spv-c2-b3r-title{
    width: 50%;
    /* background: red; */
    color:white;
    padding-right: 30%;
    font-size: 24px;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.spv-c2-b3r-title-2{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    margin-top: 4%;
    color:white;
    font-size: 21px;
    margin-bottom: 1%;
}
.spv-c2-b3r-title-3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* margin-top: 0.5%; */
    color:rgba(0, 0, 0, 0.89);
    font-size: 14px;
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.spv-c2-b3r-sub-title-1{
    background: rgb(0,0,0,0.24);
    width: 50%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-top: 1%;
    color: rgb(193, 235, 235);
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

}
.spv-c2-b3r-sub-title-3{
    background: rgb(0,0,0,0.24);
    width: 81%;
    text-align: right;
    padding-right: 7%;
    padding-left: 7%;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-bottom: 2%;
    color: rgb(175, 253, 253);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.spv-c2-b3r-box{
    width: 80%;
    height: 35%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap:wrap;
    overflow-x: auto;
}

/*  */
.spv-c2-b3r-box::-webkit-scrollbar {
    width: 8px;  
    height: 8px;
}

.spv-c2-b3r-box::-webkit-scrollbar-track {
    background: rgba(230, 230, 230, 0.114);  /* Cor do trilho */
    border-radius: 10px;  /* Bordas arredondadas */
}

.spv-c2-b3r-box::-webkit-scrollbar-thumb {
    background: rgb(63, 243, 204); /* Cor do polegar */
    border-radius: 11px; /* Bordas arredondadas */
    border: 1px solid rgb(25, 24, 24); 
}

.spv-c2-b3r-box::-webkit-scrollbar-thumb:hover {
    background: #559; /* Cor do polegar ao passar o mouse */
    cursor:grab;
}

.spv-c2-b3r-box::-webkit-scrollbar-corner {
    background: white;  /* Cor do canto */
  }



