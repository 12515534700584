button{
    background: none;
}


.modal-share{
    top: 50%;
    left: 50%;
    position: fixed;
    width: 40%;
    height: 60vh;
    background: #445;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    color: whitesmoke;
    font-family: roboto slab;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .m-1-title{
    margin-bottom: 8%;

  }
  .m-1-bt-close{
    top:5%;
    left: 91%;
    width: 5vh;
    height: 5vh;
    position: absolute;
    background-image: url('../img/close.png');
    /* background-color: none; */
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .m-1-bt-facebook{
    width: 36%;
    height: 10%;
    background: #3b5998;
    color: whitesmoke;
    font-family: roboto slab;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.16);
    margin-bottom: 1%;
    border-radius: 2px;
    border: 1px solid rgb(0,0,0,0.16);
    cursor:pointer;

  }
  .m-1-bt-twitter{
    width: 36%;
    height: 10%;
    background: #111122;
    color: whitesmoke;
    font-family: roboto slab;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.16);
    margin-bottom: 1%;
    border-radius: 2px;
    border: 1px solid rgb(0,0,0,0.16);
    cursor:pointer;

  }
  .m-1-bt-instagram{
    width: 36%;
    height: 10%;
    background:  #3f729b;
    color: whitesmoke;
    font-family: roboto slab;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.16);
    margin-bottom: 1%;
    border-radius: 2px;
    border: 1px solid rgb(0,0,0,0.16);
    cursor:pointer;

  }
  .m-1-bt-linkedin{
    width: 36%;
    height: 10%;
    background: rgb(58, 58, 139);
    color: whitesmoke;
    font-family: roboto slab;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.16);
    margin-bottom: 1%;
    border-radius: 2px;
    border: 1px solid rgb(0,0,0,0.16);
    cursor:pointer;

  }