.home-4{
    width: 100%;
    height: 100vh;
    background-image: url("../../img/home/home-3.webp");
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.h-4-bt-1{
    width: 8%;
    height: 6vh;
    background: #336699;
    border-radius: 4px;
    color: aliceblue;
    position: absolute;
    top:386vh;
    left: 17%;
    font-family: Roboto slab;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor:pointer;
    opacity: 0;
}
