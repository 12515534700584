.avx-2{
    width: 100%;
    height: 100vh;
    /* background: burlywood; */
    background-image: url("../../img/avx-2.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    /* flex-direction: column; */
    /* border-bottom: 30px solid aliceblue;  */
}
.a-2-box-address{
    width: 39%;
    height: 9vh;
    background: #444455;
    position: absolute;
    top: 176vh;
    left: 50%;
    border: 3px solid rgb(209, 209, 209);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.a-2-bt-copy{
    width: 10%;
    height: 9vh;
    /* background: red; */
    background-image: url("../../img/copy.png");
    background-position: 50% 50%;
    background-size: 60%;
    background-repeat: no-repeat;
    border-left: 3px solid rgb(209, 209, 209);
    cursor: pointer;
}
.a-2-box-title{
    width: 20%;
    height: 9vh;
    font-family: roboto slab;
    color:whitesmoke;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    border-right: 3px solid rgb(209, 209, 209);
}
.a-2-box-address-value{
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    color: white;
}

.copy-feedback {
    /* margin-top: 10px; */
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: whitesmoke;
    color: #333344;
    border-radius: 3px;
    display: inline-block;
    animation: fadeOut 3s forwards;
    position: fixed;
    top: 6vh;
    left: 82%;
    font-family: 'Courier New', Courier, monospace;
    box-shadow: 2px 2px 8px rgb(0,0,0,0.16);
}
@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }