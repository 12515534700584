.avx-1{
    width: 100%;
    height: 100vh;
    background-image: url("../../img/avx-1.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}

.location-nav-link-avx{
    top:7%;
    left: 33%;
}
.h-1-nav-link.active {
    color:#e2f255;
}
.h-1-bt-play-avx{
    width: 15%;
    height: 6vh;
    color: whitesmoke;
    background: linear-gradient(to bottom, #ffac3f, #fe9800);
    border: 1px solid #d28107;
    box-shadow: 2px 2px 5px rgb(0,0,0,0.16);
    border-radius: 6px;
    position: absolute;
    left: 78%;
    top: 7%;
    font-family: "Roboto Slab", serif;
    font-size: 12px;
    font-weight: 900;
    text-shadow: 1px 1px rgb(0,0,0,0.4);
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;

}
.h-1-icon-avx{
    width: 16%;
    height: 16vh;
    background-image: url("../../img/icon-full.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 90%;
    position: absolute;
    top: 2%;
    left: 6.5%;
    text-decoration: none;
    cursor:pointer;
}