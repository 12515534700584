.avx-3{
    width: 100%;
    height: 100vh;
    /* background: burlywood; */
    background-image: url("../../img/avx-3.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    /* flex-direction: column; */
    /* border-bottom: 30px solid aliceblue;  */
}