.public-sale-1{
    width: 100%;
    height: 100vh;
    background-image: url("../../img/public-sale/public-sale-1.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}
.p-b-link-1{
    width: 12%;
    height: 6vh;
    background-color: aliceblue;
    position:absolute;
    top: 84%;
    left: 11%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: monospace;
    border-radius: 4px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    text-decoration: none;
    box-shadow: 2px 2px 6px rgb(0,0,0,0.24);
}
.p-b-link-1:hover{
    cursor: pointer;
}